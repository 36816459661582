import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/mine/login",
  },
  // 登录
  {
    path: "/mine",
    component: () => import("@/views/mine"),
    children: [
      {
        path: "login",
        component: () => import("@/views/mine/login"),
      },
      {
        path: "register",
        component: () => import("@/views/mine/register"),
      },
    ],
  },
  // 页面
  {
    path: "",
    component: () => import("@/components/layout"),
    children: [
      // 首页
      {
        path: "home",
        component: () => import("@/views/home"),
      },
      // 品牌管理
      {
        path: "brand",
        component: () => import("@/views/brand"),
        children: [
          {
            path: "miniApp",
            component: () => import("@/views/brand/miniApp"),
          },
          {
            path: "merchant",
            component: () => import("@/views/brand/merchant"),
          },
          {
            path: "bulletin",
            component: () => import("@/views/brand/bulletin"),
          },
        ],
      },
      // 积分管理
      {
        path: "point",
        component: () => import("@/views/point"),
        children: [
          {
            path: "config",
            component: () => import("@/views/point/config"),
          },
          {
            path: "goodsList",
            component: () => import("@/views/point/goodsList"),
          },
          {
            path: "goodsCate",
            component: () => import("@/views/point/goodsCate"),
          },
          {
            path: "exchange",
            component: () => import("@/views/point/exchange"),
            children: [
              {
                path: "card",
                component: () => import("@/views/point/exchange/card"),
              },
              {
                path: "goods",
                component: () => import("@/views/point/exchange/goods"),
              },
            ],
          },
          {
            path: "order",
            component: () => import("@/views/point/order"),
            children: [
              {
                path: "card",
                component: () => import("@/views/point/order/card"),
              },
              {
                path: "goods",
                component: () => import("@/views/point/order/goods"),
              }
            ]
          },
         
        ],
      },
      // 会员管理
      {
        path: "vip",
        component: () => import("@/views/vip"),
        children: [
          {
            path: "user",
            component: () => import("@/views/vip/user"),
          },
          {
            path: "config",
            component: () => import("@/views/vip/config"),
            children: [
              {
                path: "rights",
                component: () => import("@/views/vip/config/rights"),
              },
              {
                path: "level",
                component: () => import("@/views/vip/config/level"),
              },
            ],
          },
          {
            path: "userInfo",
            component: () => import("@/views/vip/userInfo"),
            children: [
              {
                path: "baseInfo",
                component: () => import("@/views/vip/userInfo/baseInfo"),
              },
              {
                path: "userOrderList",
                component: () => import("@/views/vip/userInfo/userOrderList"),
              },
              {
                path: "userCardList",
                component: () => import("@/views/vip/userInfo/userCardList"),
              },
              {
                path: "goodList",
                component: () => import("@/views/vip/userInfo/goodList"),
              },
              {
                path: "balanceInfo",
                component: () => import("@/views/vip/userInfo/balanceInfo"),
              },
              {
                path: "charge",
                component: () => import("@/views/vip/userInfo/charge"),
              },
              {
                path: "stortGift",
                component: () => import("@/views/vip/userInfo/stortGift"),
              },
              {
                path: "memberLevel",
                component: () => import("@/views/vip/userInfo/memberLevel"),
              },
              {
                path: "growthValue",
                component: () => import("@/views/vip/userInfo/growthValue"),
              },
              {
                path: "point",
                component: () => import("@/views/vip/userInfo/point"),
              }
            ],
          },
        ],
      },
      // 商户管理
      {
        path: "commercial",
        component: () => import("@/views/commercial"),
        children: [
          {
            path: "commercialList",
            component: () => import("@/views/commercial/commercialList"),
          },
          {
            path: "shopList",
            component: () => import("@/views/commercial/shopList"),
          },
          {
            path: "serviceList",
            component: () => import("@/views/commercial/serviceList"),
          },
          {
            path: "roomList",
            component: () => import("@/views/commercial/roomList"),
          },
          {
            path: "desk",
            component: () => import("@/views/commercial/desk"),
          },
        ],
      },
      // 卡券管理
      {
        path: "card",
        component: () => import("@/views/card"),
        children: [
          {
            path: "cardList",
            component: () => import("@/views/card/cardList"),
          },
          {
            path: "cardEdit",
            component: () => import("@/views/card/cardEdit"),
          },
          {
            path: "orderList",
            component: () => import("@/views/card/orderList"),
          },
          {
            path: "batch",
            component: () => import("@/views/card/batch"),
          },
        ],
      },
      // 优惠活动
      {
        path: "activity",
        component: () => import("@/views/activity"),
        children: [
          {
            path: "activity",
            component: () => import("@/views/activity/activity"),
          },
          {
            path: "plateformActivity",
            component: () => import("@/views/activity/plateformActivity"),
          },
          {
            path: "rechargeRecord",
            component: () => import("@/views/activity/rechargeRecord"),
          },
          {
            path: "popup",
            component: () => import("@/views/activity/popup"),
          },
        ],
      },
      // 设备管理
      {
        path: "device",
        component: () => import("@/views/device"),
        children: [
          {
            path: "deviceList",
            component: () => import("@/views/device/deviceList"),
          },
          {
            path: "wayList",
            component: () => import("@/views/device/wayList"),
          },
          {
            path: "unitTypeList",
            component: () => import("@/views/device/unitTypeList"),
          },
        ],
      },
      // 平台分账管理
      {
        path: "share",
        component: () => import("@/views/share"),
        children: [
          {
            path: "percentage",
            component: () => import("@/views/share/percentage"),
          },
          {
            path: "subsidy",
            component: () => import("@/views/share/subsidy"),
          },
        ],
      },
      // 财务管理
      {
        path: "finances",
        component: () => import("@/views/finances"),
        children: [
          {
            path: "merchantOrders",
            component: () => import("@/views/finances/merchantOrders"),
          },
          {
            path: "brandOrders",
            component: () => import("@/views/finances/brandOrders"),
          },
          {
            path: "merchantWithdrawals",
            component: () => import("@/views/finances/merchantWithdrawals"),
          },
          {
            path: "storeWithdrawals",
            component: () => import("@/views/finances/storeWithdrawals"),
          },
          {
            path: "balanceCount",
            component: () => import("@/views/finances/balanceCount"),
          },
          {
            path: "subsidy",
            component: () => import("@/views/finances/subsidy"),
          },
        ],
      },
      // 充值管理
      {
        path: "recharge",
        component: () => import("@/views/recharge"),
        children: [
          {
            path: "packageSetting",
            component: () => import("@/views/recharge/packageSetting"),
          },
        ],
      },
      // 系统设置
      {
        path: "setting",
        component: () => import("@/views/setting"),
        children: [
          {
            path: "menuList",
            component: () => import("@/views/setting/menuList"),
          },
          {
            path: "myselfInfo",
            component: () => import("@/views/setting/myselfInfo"),
          },
          {
            path: "roleList",
            component: () => import("@/views/setting/roleList"),
          },
          {
            path: "userList",
            component: () => import("@/views/setting/userList"),
          },
          {
            path: "msgList",
            component: () => import("@/views/setting/msgList"),
          },
          {
            path: "serviceConfig",
            component: () => import("@/views/setting/serviceConfig"),
            children: [
              {
                path: "room",
                component: () => import("@/views/setting/serviceConfig/room"),
              },
              {
                path: "billiard",
                component: () =>import("@/views/setting/serviceConfig/billiard"),
              },
              {
                path: "board",
                component: () => import("@/views/setting/serviceConfig/board"),
              },
              {
                path: "store",
                component: () => import("@/views/setting/serviceConfig/store"),
              },
              {
                path: "desk",
                component: () => import("@/views/setting/serviceConfig/desk"),
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "hash",
});
export default router;
